import { PedidoVentaLinea } from './pedido-venta-linea';

export class PedidoAgregar {

    public intId?:number;
    public intIdCliente?:number;
    // public miListPedidoVentaLinea?: PedidoVentaLinea[] = [];
    public miListPedidoVentaLinea?: PedidoAgregarLinea[] = [];
    //CAROL 30/08/21
    public intTipoPedido?:number;
    public strObservaciones?:string;

}

export class PedidoAgregarLinea{

    public intId?:number;
    public intIdArticulo?:number;
    public strNombreArticulo?:string;
    public decCantidad?:number;

}

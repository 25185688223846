import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-ventana-emergente-pedido-notas',
  templateUrl: './ventana-emergente-pedido-notas.component.html',
  styleUrls: ['./ventana-emergente-pedido-notas.component.scss']
})
export class VentanaEmergentePedidoNotasComponent implements OnInit {

  strNotasPedido:string;
  // pedidoNotas=false;
  // tipoPedido=true;
  errorNotas=false;
  miDataResponse:DataResponse=new DataResponse();

  constructor(private thisDialogRef: MatDialogRef<MatDialog>) { }

  ngOnInit() {
  }

  // insertaPedido(e){
  //   // console.log(e)
  //   this.miDataResponse.intTipo=e;
  //   switch (e){
  //     case 1:
  //       // standard
  //       this.thisDialogRef.close(this.miDataResponse);
  //       break;

  //     case 2:
  //       this.thisDialogRef.close(this.miDataResponse);
  //       // this.tipoPedido=false;
  //       // this.pedidoNotas=true;
  //   }
  //   // this.thisDialogRef.close(e);
  // }

  guardar(){
    if(this.strNotasPedido!='' && this.strNotasPedido!=undefined){
      this.miDataResponse.strNotas=this.strNotasPedido;
      this.thisDialogRef.close(this.miDataResponse);
    }
    else{
      this.errorNotas=true;
    }
  }

  cerrar(){
    this.thisDialogRef.close();
  }

}

export class DataResponse{
  public intTipo?:number;
  public strNotas?:string;
}

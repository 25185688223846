import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { Cliente } from 'src/app/models/cliente';
import { HttpErrorResponse } from '@angular/common/http';
import { Token } from 'src/app/models/Token';
import { Login } from 'src/app/models/Login';
import { Funciones } from 'src/app/resources/Funciones';
import { Sesion } from 'src/app/models/Sesion';
import { Respuesta } from 'src/app/models/Respuesta';
import { MatDialog } from '@angular/material';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';
import { CpProvinciaResult } from 'src/app/models/cp-provincia-result';

@Component({
  selector: 'app-confirmacion-registro',
  templateUrl: './confirmacion-registro.component.html',
  styleUrls: ['./confirmacion-registro.component.scss']
})
export class ConfirmacionRegistroComponent implements OnInit {

  strCooI:string;
  strCooG:string;
  formNewCliente: FormGroup;
  submitted=false;
  showSpinner=false;
  miCliente:Cliente=new Cliente();

  //login
  miLogin: Login = new Login();
  miToken: Token = new Token();
  strUserNameRequest: string;
  strPasswordRequest: string;
  miFunciones: Funciones = new Funciones();
  miSesion: Sesion = new Sesion();
  miRespuesta: Respuesta = new Respuesta();
  strLogo:string='';

  miCpProvinciaResult: CpProvinciaResult = new CpProvinciaResult();
  strPrefijoCP:string='00';
  strSufijoCP:string='';

  constructor(private _formBuilder: FormBuilder, private miRouter:Router, private miActivatedRoute: ActivatedRoute, private dialog: MatDialog,
    private formBuilder: FormBuilder, private miRestService: LoginService,private miStorageService: StorageService) {
      this.miActivatedRoute.queryParams.subscribe(params => {
        this.strCooG=params["id"];
        this.strCooI=params["coo"];
      });
    }

  ngOnInit() {
    this.showSpinner=true;
    this.getDatos();

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellido: [''],
      dni: [''],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      direccion: new FormControl('',Validators.compose([Validators.maxLength(60),Validators.required])),
      poblacion: [''],
      provincia: [''],
      cp: new FormControl('',Validators.compose([Validators.maxLength(3),Validators.pattern('^[0-9]+$')])),
      pais: [''],
      tel1: new FormControl('',Validators.compose([Validators.maxLength(11),Validators.required])),
      tel2: new FormControl('',Validators.compose([Validators.maxLength(11)])),
    });

    this.formNewCliente.controls.email.disable();
  }

  getDatos(){
    this.miRestService.apiRecuperaRegistro(this.strCooI,this.strCooG).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.getLogo();
        this.getProvincias();
        if(datos.miListCliente.length>0){
          this.miCliente=datos.miListCliente[0]
        }
      }
      else{
        this.showSpinner=false;
      }
    })
  }

  getLogo(){
    this.miRestService.getDatosGeneralesEst(this.strCooI).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){
        this.strLogo=datos.miListEstablecimiento[0].strLogo
      }
    })
  }

  getProvincias(){
    this.miRestService.getProvincias(this.strCooI,0,'').subscribe(datos=>{
      // console.log("CP "+datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){
        this.miCpProvinciaResult=datos;
      }
    })
  }

  changeProvincia(e){
    // console.log(e)
    this.strPrefijoCP=e.value.strCod;
  }

  guardar(){
    this.submitted=true;
    this.showSpinner=true;
    // console.log(this.miCliente)

    if(this.formNewCliente.valid){
      // this.miCliente.strCP=this.strPrefijoCP+this.strSufijoCP;
      if(this.strSufijoCP!=''){this.miCliente.strCP=this.strPrefijoCP+this.strSufijoCP;}
      this.miRestService.apiConfirmaRegistro(this.strCooI,this.miCliente).subscribe(datos=>{
        // console.log(datos)
        // this.showSpinner=false;
        if(datos.miRespuesta.booOk){
          //Procedo con el login
          this.LogIn();
        }else{
          let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
            width: '600px',
            data:{
              texto:"Se ha producido un error en el proceso de confirmación del registro. Por favor inténtelo mas tarde",
              boton:false,
              btnError:true
            }
          });
          // setTimeout(() => { 
          //   this.redirigirPagoFinalizado(strFormaPago)
          // }, 1000); 
          // dialogRef.afterClosed().subscribe(()=>{
          //   this.redirigirPagoFinalizado(strFormaPago)
          // })
        }
      })
    }else{
      this.showSpinner=false;
    }
  }

  LogIn(){
    try{
      // this.submitted2 = true;
      // if(this.LogInForm2.valid){
        // this.showSpinner=true;
        // Solicito credenciales
        this.miRestService.apiUsuario(this.strCooI).subscribe((Resultado1: any) => {
          // Verifico que el dato que recibo sea un Login
          if (Resultado1.strUsuario != undefined) {
              this.miLogin = Resultado1;
              
              // Decripto la información
              this.strUserNameRequest = this.miFunciones.DesencriptaUsuarioPassword(this.miLogin.strUsuario);
              this.strPasswordRequest = this.miFunciones.DesencriptaUsuarioPassword(this.miLogin.strContrasena);

              // Solicito Token
              this.miRestService.token(this.strUserNameRequest, this.strPasswordRequest).subscribe((Resultado2: any) => {
                this.miToken = Resultado2;
                
                // Evaluo el token
                if (this.miToken.access_token != undefined && this.miToken.access_token != "") {
                  // Valido credenciales del usuario
                  this.miRestService.apiSesion(this.miCliente.strEmail, this.miCliente.strPassword, 3, this.miLogin.strCookie).subscribe((Resultado3: any) => {
                    // console.log(Resultado3)
                    // Verifico que el dato que recibo sea una Sesion
                    if (Resultado3.strCookie != undefined) {
                      this.miSesion = Resultado3;

                      // Adiciono datos faltantes
                      this.miSesion.strNombre = "";
                      this.miSesion.strNombreTitulo = "";
                      this.miSesion.miToken = this.miToken;

                      this.miRestService.getDatosGeneralesEst(this.miLogin.strCookie).subscribe(datos=>{
                        // console.log(datos);
                        if(datos.miRespuesta.booOk){
                          // Guardo datos en sesión
                          this.miSesion.strLogo=datos.miListEstablecimiento[0].strLogo;
                          this.miSesion.strEstablecimientoNombre=datos.miListEstablecimiento[0].strNombre;

                          this.miRestService.getLogo(this.miLogin.strCookie).subscribe(resultado4=>{
                            if(resultado4.miRespuesta.booOk){
                              //Guardo el nombre de la app
                              this.miSesion.strNombreApp=resultado4.miListProducto[0].strNombre;

                              this.miStorageService.setCurrentSession(this.miSesion);
                              // Redirijo a pagina inicial del usuario
                              // setTimeout(() => {
                            this.showSpinner = false;
                            this.miRouter.navigate(["/menu/carta"]);
                              // }, 5000); 
                          
                            }else{
                              console.log("No se ha podido obtener nombre app.");
                              this.showSpinner = false;
                            }
                          })

                          

                        }else{
                          console.log("No se ha podido obtener datos establecimiento.");
                          this.showSpinner = false;
                        }                    
                      })                                        

                    } else {
                      if (Resultado3.booOk != undefined) {
                        this.miRespuesta = Resultado3;
                        console.log(this.miRespuesta.strValor);
                        // this.showError=true;
                        this.showSpinner = false;
                        // this.message=this.miRespuesta.strValor;
          
                      } else {
                        console.log("El dato de respuesta no se corresponde con ningun objeto previsto.");
                        this.showSpinner = false;
                      }
                    }

                  }, (err: HttpErrorResponse) => {
                    if(err.status === 400) {
                      console.log("ERROR 400.");
                      this.showSpinner = false;
                    }
                  })

                } else {
                  console.log("ERROR: No se obtuvo el token.");
                  this.showSpinner = false;
                }

              }, (err: HttpErrorResponse) => {
                if(err.status === 400){
                  console.log("ERROR 400.");
                  this.showSpinner = false;
                }
              })

          } else {
              if (Resultado1.booOk != undefined) {
                this.miRespuesta = Resultado1;
                console.log(this.miRespuesta.strValor);
                // this.showError=true;
                this.showSpinner = false;
                // this.message=this.miRespuesta.strValor;

              } else {
                console.log("El dato de respuesta no se corresponde con ningun objeto previsto.");
                this.showSpinner = false;
              }
          }
        }, (err: HttpErrorResponse) => {
          if(err.status === 400) {
            console.log("ERROR 400.");
            this.showSpinner = false;
          }
        })
      // }
      
      
    } catch(e) {
        if(e instanceof Error) {
            // IDE type hinting now available
            console.log(e.message);
            this.showSpinner = false;
        }
        else {
            // probably cannot recover...therefore, rethrow
            throw e;
        }
    }    
  }

}

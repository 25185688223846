import { Component, OnInit, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav, MatDialog } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import { GrupoResult } from 'src/app/models/grupo-result';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';
import { NavItem } from 'src/app/services/nav-item';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Articulo } from 'src/app/models/articulo';
import { PedidoOnlineService } from 'src/app/services/pedido-online.service';
import { EventEmiterService } from 'src/app/services/nav.service';
import { LoginService } from 'src/app/services/login.service';
import { FormControl } from '@angular/forms';
import { Establecimiento } from 'src/app/models/establecimiento';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';
import { VentanaEmergentePedidoNotasComponent } from '../ventana-emergente-pedido-notas/ventana-emergente-pedido-notas.component';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { PedidoAgregar } from 'src/app/models/pedido-agregar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  // fillerNav = Array(50).fill(0).map((_, i) => `Nav Item ${i + 1}`);

  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  totalArticulos:number=0;
  breakpoint:any;
  notLogIn:boolean;
  LogIn:boolean;
  inicialUser:string;
  user:string;

  //LOGO
  textoApp:string;
  imgLogo:string;

  miGupoResult:GrupoResult=new GrupoResult();
  intId:any;
  strCookie:string;
  showContent=false;
  showDiv=true;
  showSpinner=false;

  nombreEst:string;
  strDireccion:string;

  miEstablecimiento:Establecimiento=new Establecimiento();
  miPedido:PedidoAgregar=new PedidoAgregar();
 
   onResize(event) {
     this.breakpoint = (event.target.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 5 && (window.innerWidth <= 375) ? 1 : 5;
   }
 
   mouseenter() {
     if (!this.isExpanded) {
       this.isShowing = true;
     }
   }
 
   mouseleave() {
     if (!this.isExpanded) {
       this.isShowing = false;
     }
   }

  constructor(private storageService: StorageService,private miLoginService:LoginService, private miServicio:PedidoOnlineService, private dialog: MatDialog, 
              private router:Router,private route: ActivatedRoute,private miStoragePedidoService:StoragePedidoService
    ) {
    this.route.queryParams.subscribe(params => {
      this.strCookie=params["coo"];
    });
  }

  ngOnInit() {
    this.showSpinner=true;
    this.breakpoint = (window.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 5 && (window.innerWidth <= 375) ? 1 : 5;
    
    if(this.storageService.isAuthenticated()){
      this.strCookie=this.storageService.getCurrentSession().strCookie;

      var event = new Date(this.storageService.getCurrentSession().miToken[".expires"]);
      var jsonDate = event.toJSON();
      var today = new FormControl(new Date()).value.toJSON();
      if(jsonDate>today){
      }else if(jsonDate<today){
        setTimeout(() => {
          this.openWindow();
        }, 100); 
      } 

      this.nombreEst=this.storageService.getCurrentSession().strEstablecimientoNombre;
      this.notLogIn=false;
      this.LogIn=true;
      this.inicialUser=this.storageService.getCurrentUser().strUserName.charAt(0).toUpperCase();
      this.user=this.storageService.getCurrentUser().strUserName;

      this.textoApp=this.storageService.getCurrentSession().strNombreApp;
      this.imgLogo=this.storageService.getCurrentSession().strLogo;
      this.listarGrupos(this.storageService.getCurrentSession().strCookie);
    }else{
      //Recupero los datos del establecimiento
      
      //Recupero datos producto
      this.recuperaProducto();
      this.notLogIn=true;
      this.LogIn=false;
      this.listarGrupos(this.strCookie);
    }

    //Recupero los datos del establecimiento para el pie de pagina
    this.recuperaDatosGeneralesEstablecimientos();
    
  }

  openWindow(){
    let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
      width: '600px',
      data:{
        texto:"Tu sesion ha caducado...",
        boton:false,
        btnError:true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.logout();
    });
  }

  recuperaProducto(){
    //console.log(this.strCookie)
    this.miLoginService.getLogo(this.strCookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.textoApp=datos.miListProducto[0].strNombre;
        // this.imgLogo=datos.miListProducto[0].strLogoBase64;
        // this.nombreEst=datos.miListProducto[0].strNombreEstablecimiento;
      }
    })
  }

  recuperaDatosGeneralesEstablecimientos(){
    this.miLoginService.getDatosGeneralesEst(this.strCookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.imgLogo=datos.miListEstablecimiento[0].strLogo;
        this.nombreEst=datos.miListEstablecimiento[0].strNombre;
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        // console.log(this.miEstablecimiento)

        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }
      }
    })
  }

  verDestacados(){
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/carta']);
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }
      this.router.navigate(['./menu/carta'], extra)
    }
  }

  areaCliente(){
    this.router.navigate(['/cliente/area-cliente'])
  }

  logearse(){
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.strCookie
      }
    }

    this.router.navigate(['./inicio/login'], extra)
  }

  listarGrupos(cookie){
    //console.log(cookie)
    this.miServicio.getGrupos(cookie).subscribe(datos=>{
       //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.miGupoResult=datos;
        this.showContent=true;
        this.showDiv=false;
        // console.log(this.miGupoResult);
      }else{
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde.",
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  pedidoNotas(){
    // console.log(this.miStoragePedidoService.isAuthenticatedP())
    if(!this.miStoragePedidoService.isAuthenticatedP()){
      //Verifico si esta logueado
      if(this.storageService.isAuthenticated()){
        //Continua con el pedido
        let dialogRef = this.dialog.open(VentanaEmergentePedidoNotasComponent, {
          width: '600px'
        });
    
        dialogRef.afterClosed().subscribe(data=>{
          console.log(data)
          if(data!=undefined){
            this.showSpinner=true;
            //Asigno valores
            this.miPedido.intTipoPedido=environment.pedidoEscrito;
            this.miPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
            this.miPedido.strObservaciones=data.strNotas;
            this.miPedido.intId=0;
            //Inserta pedido por notas
            this.enviaPedido(this.strCookie,this.miPedido)
          }
        })
      }else{
        //Redirijo al login
        let extra: NavigationExtras = {
          queryParams: {
            'coo':this.strCookie
          }
        }
  
        this.router.navigate(["/inicio/login"], extra);
      }
    }else{
      // this.procesoIniciaPedido(e);
      this.ventanaemergente("Existe un pedido en curso.",true,false);
    }
  }

  enviaPedido(cookie,pedido){
    this.miServicio.postPedidoNotas(cookie,pedido,true).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;

      if(datos.miRespuesta.booOk){
        //OK
        this.miPedido=new PedidoAgregar();
        this.ventanaemergente("Su pedido se ha realizado correctamente. En breves recibirá un email de confirmación.",false,true);
      }
    })
  }

  ventanaemergente(strTexto,showError,showOk){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:strTexto,
        boton:false,
        showError:showError,
        showOk:showOk
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  parentWillTakeAction($event){
    // console.log($event)
    this.intId=$event;
  }

  public logout(): void{
    // this.showSpinner = true;
    this.storageService.logout();
  }


}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ventana-emergente-pago-finalizado',
  templateUrl: './ventana-emergente-pago-finalizado.component.html',
  styleUrls: ['./ventana-emergente-pago-finalizado.component.scss']
})
export class VentanaEmergentePagoFinalizadoComponent implements OnInit {

  dosAcciones=false;
  muestraOk=false;
  muestraError=false;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) public data: miEstructura, private router:Router) { }

  ngOnInit() {
    // console.log(this.data)
    this.dosAcciones=this.data.boton;
    if(this.data.btnError){
      this.muestraError=true;
      this.muestraOk=false;
    }else{
      this.muestraError=false;
      this.muestraOk=true;
    }
  }

  pulsa(){
    this.thisDialogRef.close(this.data);
    // this.router.navigate(['/menu/pedidoFinalizado'])
  }  

  pulsa2(){
    this.thisDialogRef.close();
  } 

}

export interface miEstructura {
  texto:any;
  boton:any;
  btnError:any;
  booPagoError:any;
  booEmailError:any;
}
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material';
import { PoliticaCookiesComponent } from '../politica-cookies/politica-cookies.component';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  cookieNombre = 'CookiePedidos';
  aceptCookies:boolean=true;
  IsCookieExists:boolean=this.cookieService.check(this.cookieNombre);
  strCookie:string;

  constructor(private cookieService: CookieService,private dialog: MatDialog, private route:ActivatedRoute, private storageService: StorageService) {
    this.route.queryParams.subscribe(params => {
      // console.log(params["coo"])
      this.strCookie=params["coo"];
    });
   }

  ngOnInit() {
    // console.log(this.IsCookieExists)
    if(this.storageService.isAuthenticated()){
      this.strCookie=this.storageService.getCurrentSession().strCookie;
    }
    if(this.IsCookieExists){
      this.aceptCookies=false;
    }
    // console.log(this.strCookie)
  }

  cancelar(){
    this.aceptCookies=false;
  }

  aceptaCookies(){
    this.cookieService.set(this.cookieNombre,'CookiePedidos',365,'',window.location.hostname);
    this.aceptCookies=false;
  }

  cookies(){
    // window.open(window.location.origin + '/menu/politica-cookies');
    let dialogRef = this.dialog.open(PoliticaCookiesComponent, {
      width:'600px',
      data:this.strCookie
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.cookieService.set(this.cookieNombre,'CookiePedidos',365,'',window.location.hostname);
        this.aceptCookies=false;
      }
    })
  }

}

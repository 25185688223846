import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { User } from 'src/app/models/User';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSidenav } from '@angular/material';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { environment } from 'src/environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  /*
  loginUserData: User;
  abierto:boolean=true;
  cerrado:boolean=false;
  showSpinner: boolean = false;

  //personalizacion de menu
  isAdmin=false;
  private rolAdmin: string = environment.rolAdmin;
  private rolCliente: string = environment.rolCliente;
  hide = true;
  menuHide=false;

  //LOGO
  textoApp:string;
  imgLogo:string;

  constructor(private storageService: StorageService, private dialog: MatDialog, private miservicio:RestService) { }

  ngOnInit() {    
    this.loginUserData=this.storageService.getCurrentUser();
    this.tokenExpires();

    this.textoApp=this.storageService.getCurrentSession().strNombreApp;
    this.imgLogo=this.storageService.getCurrentSession().strLogo;

    switch(this.storageService.getCurrentUser().strRolId){
      case this.rolAdmin:        
        return this.isAdmin=true;

       case this.rolCliente:
         return this.isAdmin=false; 
    }
  }

  openCloseMenu(){
    document.getElementById("menuAccount").style.display="none";
  }

  openNav() {
    document.getElementById("miSidenav").style.width="250px";
    this.abierto=true;
    this.cerrado=false;
  }

   closeNav() {
    document.getElementById("miSidenav").style.width="0";  
    this.abierto=false;
    this.cerrado=true;    
  }

  public logout(): void{
    this.showSpinner = true;
    this.storageService.logout();
  }

  tokenExpires(){
    var event = new Date(this.storageService.getCurrentSession().miToken[".expires"]);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    if(jsonDate>today){
    }else if(jsonDate<today){
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }  
  }

  openWindow(){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:"Tu sesión ha caducado...",
        boton:false
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      if(result==="delete"){
        this.storageService.logout();
      }

    });
  }
  */

 @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
 isExpanded = true;
 showSubmenu: boolean = false;
 isShowing = false;
 showSubSubMenu: boolean = false;
 totalArticulos:number=0;
 breakpoint:any;

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 4 && (window.innerWidth <= 375) ? 1 : 4;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 4 && (window.innerWidth <= 375) ? 1 : 4;
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

}

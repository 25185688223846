export class Pasarela {

    public booOk:boolean;
    public strMensaje?:string;
    public strDsSignatureVersion?:string;
    public strDsMerchantParameters?:string;
    public strDsSignature?:string;
    public strDsUrlPeticion?:string;

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Funciones } from 'src/app/resources/Funciones';
import { StorageService } from 'src/app/services/storage.service';
import { Login } from 'src/app/models/Login';
import { Respuesta } from 'src/app/models/Respuesta';
import { Sesion } from 'src/app/models/Sesion';
import { Token } from 'src/app/models/Token';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog } from '@angular/material';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  LogInForm: FormGroup;
  LogInForm2: FormGroup;
  submitted=false;
  submitted2=false;
  strCoo: string;
  txtUsuario: string;
  txtContrasena: string;
  
  txtUsuarioNuevo: string;
  txtContrasenaNuevo: string;
  txtRepetirContrasenaNuevo: string;

  miRespuesta: Respuesta = new Respuesta();
  miFunciones: Funciones = new Funciones();
  miLogin: Login = new Login();
  miToken: Token = new Token();
  miSesion: Sesion = new Sesion();
  strUserNameRequest: string;
  strPasswordRequest: string;
  hide = true;
  hide2 = true;
  showError=false;
  message:string;
  showSpinner = false;
  strMensajeDato:string;
  // hide = true;

  strLogo:string;

  constructor(private miActivatedRoute: ActivatedRoute, private miRouter: Router, private formBuilder: FormBuilder, private miRestService: LoginService, //private miRestService: RestService,
              private miStorageService: StorageService, private dialog: MatDialog) {
    this.miActivatedRoute.queryParams.subscribe(params => {
      this.strCoo=params["coo"];       
    });
   }

  ngOnInit() {
    this.LogInForm = this.formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      contrasena: new FormControl('', Validators.compose([
        Validators.required        
      ])),
      repetircontrasena: new FormControl('', Validators.compose([
        Validators.required        
      ]))
    })

    this.LogInForm2 = this.formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      contrasena: new FormControl('', Validators.compose([
        Validators.required        
      ])),
      // repetircontrasena: new FormControl('', Validators.compose([
      //   Validators.required        
      // ]))
    })


    this.getLogo();
  }

  volver(){
    window.history.back();
  }

  getLogo(){
    this.miRestService.getDatosGeneralesEst(this.strCoo).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.strLogo=datos.miListEstablecimiento[0].strLogo
      }
    })
  }

  LogIn(){
    try{
      this.submitted2 = true;
      if(this.LogInForm2.valid){
        this.showSpinner=true;
        // Solicito credenciales
        this.miRestService.apiUsuario(this.strCoo).subscribe((Resultado1: any) => {
          // Verifico que el dato que recibo sea un Login
          if (Resultado1.strUsuario != undefined) {
              this.miLogin = Resultado1;
              
              // Decripto la información
              this.strUserNameRequest = this.miFunciones.DesencriptaUsuarioPassword(this.miLogin.strUsuario);
              this.strPasswordRequest = this.miFunciones.DesencriptaUsuarioPassword(this.miLogin.strContrasena);

              // Solicito Token
              this.miRestService.token(this.strUserNameRequest, this.strPasswordRequest).subscribe((Resultado2: any) => {
                this.miToken = Resultado2;
                
                // Evaluo el token
                if (this.miToken.access_token != undefined && this.miToken.access_token != "") {
                  // Valido credenciales del usuario
                  this.miRestService.apiSesion(this.txtUsuario, this.txtContrasena, 3, this.miLogin.strCookie).subscribe((Resultado3: any) => {
                    // Verifico que el dato que recibo sea una Sesion
                    if (Resultado3.strCookie != undefined) {
                      this.miSesion = Resultado3;

                      // Adiciono datos faltantes
                      this.miSesion.strNombre = "";
                      this.miSesion.strNombreTitulo = "";
                      this.miSesion.miToken = this.miToken;                    

                      this.miRestService.getDatosGeneralesEst(this.miLogin.strCookie).subscribe(datos=>{
                        // console.log(datos);
                        if(datos.miRespuesta.booOk){
                          // Guardo datos en sesión
                          this.miSesion.strLogo=datos.miListEstablecimiento[0].strLogo;
                          this.miSesion.strEstablecimientoNombre=datos.miListEstablecimiento[0].strNombre;

                          this.miRestService.getLogo(this.miLogin.strCookie).subscribe(resultado4=>{
                            if(resultado4.miRespuesta.booOk){
                              //Guardo el nombre de la app
                              this.miSesion.strNombreApp=resultado4.miListProducto[0].strNombre;

                              this.miStorageService.setCurrentSession(this.miSesion);
                              // Redirijo a pagina inicial del usuario
                              // setTimeout(() => {
                              this.showSpinner = false;
                              this.miRouter.navigate(["/menu/carta"]);
                              // }, 5000); 
                          
                            }else{
                              console.log("No se ha podido obtener nombre app.");
                              this.showSpinner = false;
                            }
                          })

                          

                        }else{
                          console.log("No se ha podido obtener datos establecimiento.");
                          this.showSpinner = false;
                        }                    
                      })                                        

                    } else {
                      if (Resultado3.booOk != undefined) {
                        this.miRespuesta = Resultado3;
                        console.log(this.miRespuesta.strValor);
                        this.showError=true;
                        this.showSpinner = false;
                        this.message=this.miRespuesta.strValor;
          
                      } else {
                        console.log("El dato de respuesta no se corresponde con ningun objeto previsto.");
                        this.showSpinner = false;
                      }
                    }

                  }, (err: HttpErrorResponse) => {
                    if(err.status === 400) {
                      console.log("ERROR 400.");
                      this.showSpinner = false;
                    }
                  })

                } else {
                  console.log("ERROR: No se obtuvo el token.");
                  this.showSpinner = false;
                }

              }, (err: HttpErrorResponse) => {
                if(err.status === 400){
                  console.log("ERROR 400.");
                  this.showSpinner = false;
                }
              })

          } else {
              if (Resultado1.booOk != undefined) {
                this.miRespuesta = Resultado1;
                console.log(this.miRespuesta.strValor);
                this.showError=true;
                this.showSpinner = false;
                this.message=this.miRespuesta.strValor;

              } else {
                console.log("El dato de respuesta no se corresponde con ningun objeto previsto.");
                this.showSpinner = false;
              }
          }
        }, (err: HttpErrorResponse) => {
          if(err.status === 400) {
            console.log("ERROR 400.");
            this.showSpinner = false;
          }
        })
      }
      
      
    } catch(e) {
        if(e instanceof Error) {
            // IDE type hinting now available
            console.log(e.message);
            this.showSpinner = false;
        }
        else {
            // probably cannot recover...therefore, rethrow
            throw e;
        }
    }    
  }

  register(){
    // this.showSpinner=true;
    this.submitted = true;
    if(this.LogInForm.valid){
      if(this.txtUsuarioNuevo!=undefined && this.txtContrasenaNuevo!=undefined && this.txtRepetirContrasenaNuevo!=undefined){
        this.strMensajeDato="";
  
        if(this.txtContrasenaNuevo===this.txtRepetirContrasenaNuevo){
          this.strMensajeDato="";
          this.showSpinner=true;
  
          this.miRestService.apiRegistro(this.txtUsuarioNuevo, this.txtContrasenaNuevo, this.strCoo, window.location.origin + "/inicio/confirmacion-registro").subscribe(datos=>{
            // console.log(datos)
  
            if(datos.miRespuesta.booOk){
              this.txtUsuario=this.txtUsuarioNuevo
              this.txtContrasena=this.txtContrasenaNuevo;
              this.showSpinner=false;
              // this.LogIn();
              let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
                width: '600px',
                data:{
                  texto:"Ya queda menos. En breve recibira un correo de confirmación de registro.",
                  boton:false,
                  btnError:false
                }
              });
  
            }else{
              this.showSpinner=false;
              this.strMensajeDato=datos.miRespuesta.strMensaje;
              // this.strMensajeDato="Se ha producido un error, intentelo mas tarde";
            }
  
          })
  
        }else{
          this.showSpinner=false;
          this.strMensajeDato="La contraseña no coincide";
        }
  
      }else{
        this.showSpinner=false;
        this.strMensajeDato="Por favor, rellene todos los campos";
      }
    }
    
  }


/**************************************************************************************** */
/*
hide = true;

  constructor(){}

  ngOnInit(){

  }
  */
  
}

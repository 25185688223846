import { Component, OnInit } from '@angular/core';
import { PedidoOnlineService } from 'src/app/services/pedido-online.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';

@Component({
  selector: 'app-pedido-finalizado',
  templateUrl: './pedido-finalizado.component.html',
  styleUrls: ['./pedido-finalizado.component.scss']
})
export class PedidoFinalizadoComponent implements OnInit {

  // showCarrito=false;
  totalArticulos=0;
  strTexto:string;

  constructor(private miServicio:PedidoOnlineService, private dialog: MatDialog, private route: ActivatedRoute, private router:Router, 
    private storageService: StoragePedidoService) {}

  ngOnInit() {
    // this.storageService.removeCurrentSessionP();
    switch(this.route.snapshot.params.intId){
      case '1':
        //Todo correcto
        this.strTexto="Su pedido se ha realizado correctamente, en breves recibirá un correo electrónico de confirmación."
        break;

      case '2':
        //Pedido incorrecto, email correcto
        this.strTexto="Su pedido se ha realizado correctamente, pero se ha producido un error al enviar el email de confirmación. Por favor revise sus datos en el área de cliente"
        break;

      case '3':
        //pedido correcto, email incorrecto
        this.strTexto="Se ha producido un error al finalizar el pedido. Por favor inténtelo mas tarde. Disculpe las molestias."
        break;

      case '4':
        //Todo incorrecto
        this.strTexto="Se ha producido un error al finalizar el pedido y al intentar enviar el email. Por favor revise sus datos en el área de clientes. Disculpe las molestias."
        break;
    }
  }

  verResumen(){
    this.router.navigate(['./menu/carta'])
  }

  volver(){
    this.router.navigate(['./menu/carta'])
  }

}

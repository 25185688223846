import { PedidoVentaLinea } from './pedido-venta-linea';

export class PedidoVenta {

    public intId?: number;
    public datFechaRegistro?: Date;
    public datFechaModificacion?: Date;
    public strSerie?: string;
    public intEjercicio?: number;
    public intNPedido?: number;
    public datFecha?: Date;
    public intIdCliente?: number;
    public strNombreCliente?: string;
    public strObservaciones?: string;
    public strEstado?: string;
    public strTipo?: string;
    public intIdFormaPago?: number;
    public strFormaEntrega?:string;
    public strHoraEntrega?:string;
    public strDescargado?: string;
    public datFechaDescargado?: Date;
    public strNotasDescargado?:string;
    public decBase?: number;
    public decCuotaIva?: number;
    public decCuotaReq?: number;
    public decCuotaIrpf?: number;
    public decImporte?: number;
    public intIdExterno?: number;

    //Unicamente para pedidos sin loguearse
    public strCookie?:string; 

    public miListPedidoVentaLinea?: PedidoVentaLinea[] = [];

    //CAROL 30/08/21 LOMILLOS
    public intTipoPedido?:number;

}

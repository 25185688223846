import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ventana-emergente-pagos',
  templateUrl: './ventana-emergente-pagos.component.html',
  styleUrls: ['./ventana-emergente-pagos.component.scss']
})
export class VentanaEmergentePagosComponent implements OnInit {

  dosAcciones=false;
  muestraOk=false;
  muestraError=false;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) public data: miEstructura) { }

  ngOnInit() {
    this.dosAcciones=this.data.boton;
    if(this.data.btnError){
      this.muestraError=true;
      this.muestraOk=false;
    }else{
      this.muestraError=false;
      this.muestraOk=true;
    }
  }

  pulsa(){
    this.thisDialogRef.close('delete');
  }  

  pulsa2(){
    this.thisDialogRef.close();
  } 

}

export interface miEstructura {
  texto:any;
  boton:any;
  btnError:any;
}
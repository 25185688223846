import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { PedidoOnlineService } from 'src/app/services/pedido-online.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';

@Component({
  selector: 'app-politica-condiciones-envio-facturacion',
  templateUrl: './politica-condiciones-envio-facturacion.component.html',
  styleUrls: ['./politica-condiciones-envio-facturacion.component.scss']
})
export class PoliticaCondicionesEnvioFacturacionComponent implements OnInit {

  showCarrito=false;
  totalArticulos=0;

  constructor(private miServicio:PedidoOnlineService, private dialog: MatDialog, private route: ActivatedRoute, private router:Router, 
    private storageService: StorageService, private miStoragePedidoService:StoragePedidoService) {}

  ngOnInit() {
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.showCarrito=true;
      this.recuperarPedido(this.storageService.getCurrentSession().strCookie,this.miStoragePedidoService.getCurrentSessionP().intId);
    }else{
      this.showCarrito=false;
    }
  }

  recuperarPedido(cookie,intId){
    this.miServicio.getPedido(cookie,intId).subscribe(datos=>{
      // console.log(datos)
      this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length;
    })
  }
  
  volver(){
    this.router.navigate(['./menu/finalizarCompra'])
  }

  verResumen(){
    this.router.navigate(['./menu/resumen'])
  }

}

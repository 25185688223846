import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  strCookie:string;

  constructor(private storageService: StorageService,private route: ActivatedRoute,private router:Router) {
    this.route.queryParams.subscribe(params => {
      this.strCookie=params["coo"];
    });
   }

  ngOnInit() {
    this.storageService.removeCurrentSession();
    setTimeout(() => {
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      this.router.navigate(['./menu/carta'], extra)
    }, 500);

  }

}

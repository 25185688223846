import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { OrdenCobroResult } from 'src/app/models/OrdenCobroResult';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { LoginService } from 'src/app/services/login.service';
import { OrdenCobro } from 'src/app/models/OrdenCobro';
import { Establecimiento } from 'src/app/models/establecimiento';
import { Cliente } from 'src/app/models/cliente';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
// import { OrdenCobroObservacion } from 'src/app/models/ordenCobroObservacion';

@Component({
  selector: 'app-pago-finalizado',
  templateUrl: './pago-finalizado.component.html',
  styleUrls: ['./pago-finalizado.component.scss']
})
export class PagoFinalizadoComponent implements OnInit {

  showInfi=true;
  muestraResumen=false;
  miOrden:OrdenCobro=new OrdenCobro();
  miEstablecimiento:Establecimiento=new Establecimiento();
  miCliente:Cliente=new Cliente();
  // miListOrdenObservaciones: OrdenCobroObservacion[] = [];

  //LOGO
  textoApp:string;
  imgLogo:string;

  devuelveOk=false;
  devuelveError=false;

  //Formar de pago
  formaPago:any;

  constructor(private miservicio:RestService,private storageService: StorageService, private miRestService: LoginService, private router:Router,
              private route: ActivatedRoute) {
                // this.route.queryParams.subscribe(params => {
                //   this.formaPago=params["param"];
                // });
              }

  ngOnInit() {
    // this.recuperoPago();
    // console.log(this.formaPago)
  }

  otrosDatosEstablecimiento(){
    // this.miRestService.getLogo().subscribe(datos=>{
    //   // console.log(datos)
    //   this.textoApp=datos.strMensaje;
    //   this.imgLogo=datos.strValor;
    // })
  }

/*
  //Recupero los datos del pago
  recuperoPago(){
    this.miservicio.recuperaDato(this.storageService.getCurrentSessionP().strCookieI,this.storageService.getCurrentSessionP().strCookieT).subscribe((datos:OrdenCobroResult)=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        // this.detalles();
        this.datosEstablecimiento();
        this.otrosDatosEstablecimiento();

        setTimeout(() => {
          this.showInfi=false;
          this.muestraResumen=true;
        }, 2000);       

        //Verifico el metodo de pago
        switch(datos.miListOrdenCobro[0].strFormaPago){
          case "T":
            this.devuelveOk=true;
            this.devuelveError=false;
            this.formaPago="Transferencia";
            break;
          
          case "C":
            this.devuelveOk=true;
            this.devuelveError=false;
            this.formaPago="Cargo en cuenta bancaria";
            break;

          default:
            this.formaPago="Tarjeta";
            // if(datos.miListOrdenCobro[0].strEstadoTransaccion=environment.estadoPasarelaOk)
            switch(datos.miListOrdenCobro[0].strEstadoTransaccion){
              case environment.estadoPasarelaPendiente:
                // document.getElementById("success-box").style.display="none";
                // document.getElementById("error-box").style.display="block";
                this.devuelveError=true;
                this.devuelveOk=false;
                break;
              
              case environment.estadoPasarelaError:
                // document.getElementById("success-box").style.display="none";
                // document.getElementById("error-box").style.display="block";
                this.devuelveError=true;
                this.devuelveOk=false;
                break;

              case environment.estadoPasarelaOk:
                // document.getElementById("success-box").style.display="block";
                // document.getElementById("error-box").style.display="none";
                this.devuelveError=false;
                this.devuelveOk=true;
                break;
            }
            break
        }        
      }
    })
  }
  */

/*
  detalles(){
    this.miservicio.getDetalle(this.storageService.getCurrentSessionP().strCookieI, this.storageService.getCurrentSessionP().strCookieT).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miOrden=datos.miListOrdenCobro[0];
        // console.log(this.miOrden)

        this.miservicio.getClientePago(this.storageService.getCurrentSessionP().strCookieI,this.miOrden.intIdCliente,'','').subscribe(data=>{
          // console.log(data);
          this.miCliente=data.miListCliente[0];
          this.listarObservaciones();
        })
      }
    })
  }
  */
/*
  datosEstablecimiento(){
    this.miservicio.getEst(this.storageService.getCurrentSessionP().strCookieI).subscribe(datos=>{
      // console.log(datos)
      this.miEstablecimiento=datos[0];
      // console.log(this.miEstablecimiento)
    })
  }
  */
/*
  login(){
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.storageService.getCurrentSessionP().strCookieI
      }
    }
    this.router.navigate(["/login"], extra);    
  }
  */

  descargarPDF(){
    const linkSource = 'data:application/pdf;base64,' + this.miOrden.strArchivoTransaccion;
    const downloadLink = document.createElement("a");
    const fileName = "Factura_"+ this.miOrden.strNumeroFacturaExterno +".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();        
  }

  /*
  listarObservaciones(){
    this.miservicio.getOrdenCobroObservaciones(this.storageService.getCurrentSessionP().strCookieI,this.miOrden.intId).subscribe(datos=>{
      // console.logs(datos)
      if(datos.miRespuesta.booOk){
        this.miListOrdenObservaciones=datos.miListOrdenCobroObservacion;        
      }
    })
  }
  */

}

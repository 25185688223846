import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Funciones } from 'src/app/resources/Funciones';
import { Cliente } from 'src/app/models/cliente';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss']
})
export class DetalleClienteComponent implements OnInit {

  miCliente:Cliente=new Cliente();
  existError=false;
  mensajeError:string;
  formNewCliente: FormGroup;
  submitted = false;
  isChecked:boolean;
  Estado:string;
  dniError=false;
  miFuncion: Funciones = new Funciones();
  strEmail:string="";
  strPassword:string="";
  strPasswordConfirm:string="";
  showSpinner=false;
  strCookie:string;

  constructor(private _formBuilder: FormBuilder,private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private id: any,
              private miServicio:RestService, private storagaService:StorageService) { }

  ngOnInit() {
    this.showSpinner=true;
    this.strCookie=this.storagaService.getCurrentSession().strCookie;

    this.formNewCliente = this._formBuilder.group({
      nombre: [''],
      apellido: [''],
      dni: [''],
      usuario: [''],
      // email: [''],
      // pass:[''],
      // confirmPass:[''],
      direccion: [''],
      poblacion: [''],
      provincia: [''],
      cp: [''],
      pais: [''],
      tel1: [''],
      tel2: ['']
      // activo: ['']
    })

    this.formNewCliente.controls.usuario.disable();

    this.verDatosCliente();
  }

  verDatosCliente(){
    this.miServicio.getClienteDetalle(this.strCookie,this.id).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        // this.miCliente=datos.miListCliente[0]
        if(datos.miListCliente.length>0){
          this.miCliente=datos.miListCliente[0]
        }
      }
    })
  }

  btnGuardar(){
    this.showSpinner=true;
    this.miServicio.putCliente(this.strCookie,this.miCliente).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.miCliente=datos.miListCliente[0]
        this.thisDialogRef.close(datos.miListCliente[0]);
      }else{
        this.existError=true;
        this.mensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

  /*
  pulsa(){
    if(this.isChecked){
      this.Estado='Inactivo';      
    }else{
      this.Estado="Activo";      
    }
  }

  listarCliente(){
    this.miServicio.getCliente(this.id,'','').subscribe(datos=>{
      // console.log(datos)

      this.miServicio.getPass(this.id).subscribe(data=>{
        // console.log(data)
        if(data.booOk){
          this.miCliente.strPassword=data.strValor;
          // this.miCliente.strPasswordConfirm=data.strValor;
        }
      })

      this.miCliente=datos.miListCliente[0];
      this.strEmail=datos.miListCliente[0].strEmail;
      if(this.miCliente.strActivo==="S"){
        this.isChecked=true;
        this.Estado="Activo";
      }else{
        this.isChecked=false;
        this.Estado="Inactivo";
      }
    })
  } 

  btnGuardar(){
    this.showSpinner=true;
    if(this.isChecked){
      this.miCliente.strActivo="S";
    }else{
      this.miCliente.strActivo="N";
    }
    this.miCliente.strTipoCliente="2";

    this.submitted=true;
    //Validaciones del formulario
    // if(this.formNewCliente.valid){    
      
      //POR EL MOMENTO SE DESHABILITA VALIDACION DE DNI
      
      // //verifico que el dni sea valido
      // // if(this.miFuncion.nif(this.formNewCliente.controls.dni.value)){
      //   //oculto el error de dni erroneo
      //   this.dniError=false;   
      
      // if(this.miCliente.strPassword===this.miCliente.strPasswordConfirm){
        this.existError=false;

      // }else{
      //   this.existError=true;
      //   this.mensajeError="La contraseña no coincide."
      //   this.showSpinner=false;
      // }        

      // }else{
      //   this.dniError=true;
      // }            
    // }else{
    //   this.showSpinner=false;
    // }    
  }

  update(){
    this.miServicio.putCliente(this.miCliente).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListCliente);
      }else{
        this.existError=true;
        this.mensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }
  */

}

export class Articulo {

    public intId?: number;
    public strNombre?: string;
    public strDescripcion?:string;
    public intIdGrupo?: number;
    public strNombreGrupo?: string;
    public intIdGrupoSub1?: number;
    public strNombreGrupoSub1?: string;
    public decIvaCompra?: number;
    public decPc1?: number;
    public decPc2?: number;
    public decPc3?: number;
    public decPc4?: number;
    public decPc5?: number;
    public decIvaVenta?: number;
    public decPv1?: number;
    public decPv2?: number;
    public decPv3?: number;
    public decPv4?: number;
    public decPv5?: number;
    public decPvp1?: number;
    public decPvp2?: number;
    public decPvp3?: number;
    public decPvp4?: number;
    public decPvp5?: number;
    public strActivo?: string;
    public strDestacado?: string;
    public strIdExterno?:string;
    public intTipo?: number;
    public strDescargado?: string;
    public strAlergenos?: string;
    public strMedida?: string;
    public strImagen1Base64?: string;
    public strImagen2Base64?: string;
    public strImagen3Base64?: string;
    public strImagen4Base64?: string;
    public strImagen5Base64?: string;

    //CAROL 27/08/21 LOMILLOS
    public strComposicion?:string;

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { OrdenCobroResult } from '../models/OrdenCobroResult';
import { ClienteResult } from '../models/cliente-result';
import { Respuesta } from '../models/Respuesta';
import { Establecimiento } from '../models/establecimiento';
import { EstablecimientoResult } from '../models/EstablecimientoResult';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { GrupoResult } from '../models/grupo-result';
import { ArticuloResult } from '../models/articulo-result';
import { PedidoVentaResult } from '../models/pedido-venta-result';
import { PedidoAgregar } from '../models/pedido-agregar';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient, private storageService: StorageService ) { }
  
  public url: string = environment.baseUrl;  
  headers = new HttpHeaders().set('Content-Type','application/json');

  private strToken=this.storageService.getCurrentToken();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.strToken  })
  };

  public appDrawer: any;
    
  /*
  apiOrdenCobro(strCookie: string, intId: string, intIdCliente: string, strEstado: string, strBusqueda: string): Observable<any>{
    let dato = '{"strCookie": "'+ strCookie +'", "intId":"'+ intId +'", "intIdCliente":"'+ intIdCliente +'", "strEstado":"'+ strEstado +'", "strBusqueda":"'+ strBusqueda +'"}';
    return this.http.post<any>(this.url + '/api/ordencobro', dato, {headers: this.headers});
  }

  getOrdenCobro(intId,intIdCliente,strEstado,strBusqueda):Observable<OrdenCobroResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie+'&intId='+intId+'&intIdCliente='+intIdCliente+'&strEstado='+strEstado+'&strBusqueda='+strBusqueda;
    let urlCompleta=this.url+'/api/ordencobro?'+dato;
 
    return this.http.get<OrdenCobroResult>(urlCompleta,this.httpOptions);
  }

  postOrdenCobro(NuevaOrdenCobro,fecha):Observable<OrdenCobroResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/ordencobro?'+dato;

    NuevaOrdenCobro.datFechaFinTransaccion=fecha;

    var jsonString = JSON.stringify(NuevaOrdenCobro);
 
    return this.http.post<OrdenCobroResult>(urlCompleta,jsonString,this.httpOptions);
  }

  putOrdenCobro(NuevaOrdenCobro,fecha):Observable<OrdenCobroResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/ordencobro?'+dato;

    NuevaOrdenCobro.datFechaFinTransaccion=fecha;

    var jsonString = JSON.stringify(NuevaOrdenCobro);
 
    return this.http.put<OrdenCobroResult>(urlCompleta,jsonString,this.httpOptions);
  }

  getCliente(intId,strActivo,strTipoCliente):Observable<ClienteResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie+'&intId='+intId+'&strActivo='+strActivo+'&strTipoCliente='+strTipoCliente;
    let urlCompleta=this.url+'/api/cliente?'+dato;
 
    return this.http.get<ClienteResult>(urlCompleta,this.httpOptions);
  }

  getClientePago(Cookie,intId,strActivo,strTipoCliente):Observable<ClienteResult>{
    let dato='strCookie='+Cookie+'&intId='+intId+'&strActivo='+strActivo+'&strTipoCliente='+strTipoCliente;
    let urlCompleta=this.url+'/api/cliente/pago?'+dato;
 
    return this.http.get<ClienteResult>(urlCompleta,{headers:this.headers});
  }

  postCliente(NuevoCliente):Observable<ClienteResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/cliente?'+dato;

    var jsonString = JSON.stringify(NuevoCliente);
 
    return this.http.post<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
  }

  putCliente(NuevoCliente):Observable<ClienteResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/cliente?'+dato;

    var jsonString = JSON.stringify(NuevoCliente);
 
    return this.http.put<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
  }

  envioEmail(strUrl, intCliente, intCobro):Observable<any>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/ordencobro/email?'+dato+'&strUrl='+strUrl+'&intId='+intCliente+'&intCobro='+intCobro;
 
    return this.http.get<any>(urlCompleta,this.httpOptions);
  }

  getDetalle(cookieInfi,strCookieT):Observable<OrdenCobroResult>{
    let urlCompleta=this.url+'/api/ordencobro/detalle?strCookie='+cookieInfi+'&strCookieT='+strCookieT;
 
    return this.http.get<OrdenCobroResult>(urlCompleta,{headers:this.headers});
  }

  getOrdenCobroObservaciones(strCookie,intId):Observable<OrdenCobroObservacionResult>{
    let dato='strCookie='+strCookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/OrdenCobroObservacion?'+dato;
 
    return this.http.get<OrdenCobroObservacionResult>(urlCompleta,{headers:this.headers});
  }
  */

 

//  getPedido(cookie,intId):Observable<PedidoVentaResult>{
//   let dato='strCookie='+cookie+'&intId='+intId;
//   let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/detalle?'+dato;

//   return this.http.get<PedidoVentaResult>(urlCompleta,{headers:this.headers});
// }

getPedido(cookie,intId):Observable<PedidoVentaResult>{
  let dato='strCookie='+cookie+'&intId='+intId;
  let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/detalle?'+dato;

  return this.http.get<PedidoVentaResult>(urlCompleta,{headers:this.headers});
}

getPedidoCliente(cookie,intId,booOcultaImagenLineas):Observable<PedidoVentaResult>{
  let dato='strCookie='+cookie+'&intId='+intId+'&booOcultaImagenLineas='+booOcultaImagenLineas;
  let urlCompleta=this.url+'/api/cliente/pedidos?'+dato;

  return this.http.get<PedidoVentaResult>(urlCompleta,this.httpOptions);
}

getPedidoClienteDetalle(cookie,intId):Observable<PedidoVentaResult>{
  let dato='strCookie='+cookie+'&intId='+intId;
  let urlCompleta=this.url+'/api/cliente/pedido/detalle?'+dato;

  return this.http.get<PedidoVentaResult>(urlCompleta,this.httpOptions);
}

vinculaPedidoCliente(cookie,inPedidoId,intClienteId):Observable<PedidoVentaResult>{
  let dato='strCookie='+cookie;
  let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/asignacliente?'+dato;

  var jsonString = '{"intId": "'+ inPedidoId +'", "intIdCliente":"'+ intClienteId + '"}';

  return this.http.put<PedidoVentaResult>(urlCompleta,jsonString,this.httpOptions);
}

getClienteDetalle(cookie,intId):Observable<ClienteResult>{
  let dato='strCookie='+cookie+'&intId='+intId;
  let urlCompleta=this.url+'/api/cliente/detalle?'+dato;

  return this.http.get<ClienteResult>(urlCompleta,this.httpOptions);
}

putCliente(cookie,miCliente):Observable<any>{
  let dato='strCookie='+cookie;
  let urlCompleta=this.url+'/api/cliente/actualiza?'+dato;
  var jsonString = JSON.stringify(miCliente);
  
  return this.http.put<any>(urlCompleta,jsonString,this.httpOptions);
}

finalizaPedido(cookie,miFinalizaPedido):Observable<PedidoVentaResult>{
  let dato='strCookie='+cookie;
  let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/finaliza?'+dato;

  // var jsonString = '{"intId": '+ inPedidoId +', "strObservaciones":"'+ strObservaciones +'", "intIdFormaPago":'+ intIdFormaPago + ', ';
  // jsonString+='"strFormaEntrega":"'+ strFormaEntrega +'", "strHoraEntrega":"'+ strHoraEntrega +'", "strSubTipo":"'+ strSubTipo +'"}';

  var jsonString = JSON.stringify(miFinalizaPedido);

  return this.http.put<PedidoVentaResult>(urlCompleta,jsonString,this.httpOptions);
}

//Eliminar linea y/o pedido
delPedido_linea(cookie,intIdPedido,intIdPedidoLinea):Observable<any>{
  let dato='strCookie='+cookie+'&intIdPedido='+intIdPedido+'&intIdPedidoLinea='+intIdPedidoLinea;
  let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/elimina?'+dato;
  
  return this.http.delete<any>(urlCompleta,{headers:this.headers});
}

//Cambiar contraseña
cambioContrasena(cookie,miObjeto,intCliente):Observable<ClienteResult>{
  let dato='strCookie='+cookie+'&intCliente='+intCliente;
  let urlCompleta=this.url+'/api/cliente/contrasena?'+dato;

  var jsonString = JSON.stringify(miObjeto);

  return this.http.put<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
}

/*****************************/

  getPass(intId):Observable<Respuesta>{
    let urlCompleta=this.url+'/api/Cliente?strCookie='+this.storageService.getCurrentSession().strCookie+'&intId='+intId;
 
    return this.http.get<Respuesta>(urlCompleta,this.httpOptions);
  }

  getEst(cookieInfi):Observable<any>{
    let urlCompleta=this.url+'/api/Establecimiento?strCookie='+cookieInfi+'&datoAdicional=2';
 
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }

  getEstablecimiento():Observable<Establecimiento>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/Establecimiento?'+dato;
 
    return this.http.get<Establecimiento>(urlCompleta,this.httpOptions);
  }

  putEstablecimiento(establacimiento):Observable<EstablecimientoResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/Establecimiento?'+dato;

    var jsonString = JSON.stringify(establacimiento);
 
    return this.http.put<EstablecimientoResult>(urlCompleta,jsonString,this.httpOptions);
  }

  //Recupero el pago
  recuperaDato(cookieInfi,cookieTpv){
    let dato='strCookie='+cookieInfi;
    let urlCompleta=this.url+'/api/Pasarela?'+dato;

    var body = '{"strImporte":"" ,"strOrdenCobroId":"", "strCookieInfi":"'+ cookieInfi +'", "strCookieTpv":"'+ cookieTpv +'", "strNumeroOrden":""}';
 
    return this.http.put(urlCompleta,body,{headers:this.headers});
  }

  postPasarela(cookieInfi,cookieTpv){
    let dato='strCookie='+cookieInfi;
    let urlCompleta=this.url+'/api/Pasarela?';

    var body = '{"strImporte":"" ,"strOrdenCobroId":"", "strCookieInfi":"'+ cookieInfi +'", "strCookieTpv":"'+ cookieTpv +'", "strNumeroOrden":""}';
 
    return this.http.post(urlCompleta,body,{headers:this.headers});
  }

  /********** PAGO POR TRANSFERENCIA *************/
  pagoTransf(cookieInfi, intCliente, intCobro,strEmail,strFormaPago):Observable<any>{
    let urlCompleta=this.url+'/api/Pago/email?strCookie='+cookieInfi+'&intId='+intCliente+'&intCobro='+intCobro+'&strEmail='+strEmail+'&strFormaPago='+strFormaPago;
 
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }

  /********** PAGO POR CARGO EN CUENTA *************/
  addCC(cookieInfi,ordenCobro):Observable<any>{
    let urlCompleta=this.url+'/api/Pago/cc?strCookie='+cookieInfi;
    var jsonString = JSON.stringify(ordenCobro);
 
    return this.http.put<any>(urlCompleta,jsonString,{headers:this.headers});
  }

  /********** Exportar a EXCEL *************/

  exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  /********** Menu, Sub items *************/

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

}

import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, OnDestroy, HostListener } from '@angular/core';
import { MatSidenav, MatDialog } from '@angular/material';
import { VentanaEmergentePagosComponent } from '../components/ventana-emergente-pagos/ventana-emergente-pagos.component';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Articulo } from '../models/articulo';
import { PedidoAgregar, PedidoAgregarLinea } from '../models/pedido-agregar';
import { StorageService } from '../services/storage.service';
import { PedidoVentaLinea } from '../models/pedido-venta-linea';
import { PedidoVenta } from '../models/pedido-venta';
import { PedidoOnlineService } from '../services/pedido-online.service';
import { EventEmiterService } from '../services/nav.service';
import { Subscription } from 'rxjs';
import { StoragePedidoService } from '../services/storage-pedido.service';
import { LoginService } from '../services/login.service';
import { VentanaEmergenteTipoPedidoComponent } from '../components/ventana-emergente-tipo-pedido/ventana-emergente-tipo-pedido.component';
import { environment } from 'src/environments/environment';
import { VentanaEmergentePedidoNotasComponent } from '../components/ventana-emergente-pedido-notas/ventana-emergente-pedido-notas.component';
import { VentanaEmergenteComponent } from '../components/ventana-emergente/ventana-emergente.component';

@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss']
})
export class CartaComponent implements OnInit{

  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  totalArticulos:number=0;
  breakpoint:any;
  miListArticulo:Articulo[]=[];
  dataTodo:Articulo[]=[];
  // notFoundA=false;
  titulo:string="";
  miPedido:PedidoAgregar=new PedidoAgregar();
  miLineaPedido:PedidoAgregarLinea=new PedidoAgregarLinea();
  // miLinea:Articulo=new Articulo();
  booGuardaPedidoSesion=false;
  miPedidoSesion:PedidoVenta=new PedidoVenta();

  strCookie:string;
  showCarrito=false;
  showSpinner=false;
  strFiltro:any;
  disableFiltro=false;

  //Para las busquedas
  miListArticulosDestacados:Articulo[]=[];
  miListArticulosGrupos:Articulo[]=[];
 
   onResize(event) {
     this.breakpoint = (event.target.innerWidth <= 1000 && window.innerWidth > 450) ? 2 : 5 && (window.innerWidth <= 450) ? 1 : 5;
   }
 
   mouseenter() {
     if (!this.isExpanded) {
       this.isShowing = true;
     }
   }
 
   mouseleave() {
     if (!this.isExpanded) {
       this.isShowing = false;
     }
   }

  constructor(private miServicio:PedidoOnlineService, private miStoragePedidoService:StoragePedidoService, private dialog: MatDialog, private route: ActivatedRoute, private router:Router, 
    private storageService: StorageService) {
    this.route.queryParams.subscribe(params => {
      this.strCookie=params["coo"];
    });
  }

  ngOnInit() {
    this.showSpinner=true;
    // console.log(this.miPedido)
    this.breakpoint = (window.innerWidth <= 1000 && window.innerWidth > 450) ? 2 : 5 && (window.innerWidth <= 450) ? 1 : 5;
    // console.log(this.route.snapshot.data.items)
    if(this.storageService.isAuthenticated()){
      this.strCookie=this.storageService.getCurrentSession().strCookie
      this.getArticulos(this.storageService.getCurrentSession().strCookie);
    }else{
      this.getArticulos(this.strCookie);
    }
    //console.log(this.storageService.getCurrentSession().strCookie)

    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.showCarrito=true;
      this.recuperarPedido(this.strCookie,this.miStoragePedidoService.getCurrentSessionP().intId);
      // this.totalArticulos=this.storageService.getCurrentSessionP().miListPedidoVentaLinea.length;
    }else{
      this.showCarrito=false;
    }
  }

  verResumen(){
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/resumen'])
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      this.router.navigate(['./menu/resumen'], extra)
    }
  }

  getArticulos(cookie){
    this.showSpinner=true;
    this.route.params.subscribe(routeParams => {      
      if(routeParams.intGrupo!=undefined){
        this.listarArticulos(cookie,routeParams.intGrupo,routeParams.intId);
      }else{
        this.titulo="Promociones";
        this.verDestacados(cookie);
      }
    });
  }

  verDestacados(cookie){
    this.showSpinner=true;
    this.miServicio.getArticulosDestacados(cookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.titulo="Promociones";
        this.showSpinner=false;
        // this.miListArticulo.splice(0,this.miListArticulo.length)
        if(datos.miListArticulo.length>0){
          this.miListArticulo=datos.miListArticulo;
          this.miListArticulosDestacados=datos.miListArticulo;
          // console.log(this.miListArticulosDestacados)
          // this.notFoundA=false;
        }else{
          // this.notFoundA=true;
        }
      }else{
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde.",
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  listarArticulos(cookie,intGrupo,intId){
    this.showSpinner=true;
    //console.log(cookie,intGrupo,intId)
    this.miServicio.getArticulos(cookie,intGrupo,intId).subscribe(datos=>{
       //console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        
        if(datos.miListArticulo.length>0){
          // console.log(datos.miListArticulo)
          // this.miListArticulo.splice(0,this.miListArticulo.length)
          this.miListArticulo=datos.miListArticulo;
          this.miListArticulosGrupos=datos.miListArticulo;
          // console.log(this.miListArticulosGrupos)
          // this.notFoundA=false;
          this.titulo=datos.miListArticulo[0].strNombreGrupo + " / " + datos.miListArticulo[0].strNombreGrupoSub1;
        }else{
          // this.notFoundA=true;
        }
      }else{
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde.",
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  detalle(e){
    // console.log(e)
    
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/detalle', e.intId])
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      // console.log(this.strCookie)
      this.router.navigate(['./menu/detalle', e.intId], extra)
    }
  }

  addPedido(e){
    //CAROL 27/08/21
    //Cambio para Lomillos
    //Verifico si existe pedido, si no existe pregunto tipo de pedido
    if(!this.miStoragePedidoService.isAuthenticatedP()){
      let dialogRef = this.dialog.open(VentanaEmergenteTipoPedidoComponent, {
        width: '600px'
      });
  
      dialogRef.afterClosed().subscribe(data=>{
        // console.log(data)
        if(data!=undefined){
          switch(data.intTipo){
            case 1:
              //standard
              this.miPedido.intTipoPedido=environment.pedidoStandard;
              this.procesoIniciaPedido(e);
              break;

            case 2:
              //pedido por nota
              // this.miPedido.intTipoPedido=environment.pedidoMixto;
              // this.procesoIniciaPedido(e);
              //Verifico si esta logueado
              if(this.storageService.isAuthenticated()){
                //Continua con el pedido
                let dialogRef = this.dialog.open(VentanaEmergentePedidoNotasComponent, {
                  width: '600px'
                });
            
                dialogRef.afterClosed().subscribe(data=>{
                  console.log(data)
                  if(data!=undefined){
                    this.showSpinner=true;
                    //Asigno valores
                    this.miPedido.intTipoPedido=environment.pedidoEscrito;
                    this.miPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
                    this.miPedido.strObservaciones=data.strNotas;
                    this.miPedido.intId=0;
                    //Inserta pedido por notas
                    this.enviaPedidoNotas(this.strCookie,this.miPedido)
                  }
                })
              }else{
                //Redirijo al login
                let extra: NavigationExtras = {
                  queryParams: {
                    'coo':this.strCookie
                  }
                }
          
                this.router.navigate(["/inicio/login"], extra);
              }
              break;
          }
        }
      })
    }else{
      this.procesoIniciaPedido(e);
    }

  //  this.totalArticulos+=1;
  }

  enviaPedidoNotas(cookie,pedido){
    this.miServicio.postPedidoNotas(cookie,pedido,true).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;

      if(datos.miRespuesta.booOk){
        //OK
        this.miPedido=new PedidoAgregar();
        this.ventanaemergente("Su pedido se ha realizado correctamente. En breves recibirá un email de confirmación.",false,true);
      }
    })
  }

  ventanaemergente(strTexto,showError,showOk){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:strTexto,
        boton:false,
        showError:showError,
        showOk:showOk
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  procesoIniciaPedido(e){
    this.showSpinner=true;
    this.miLineaPedido.intId=0;
    this.miLineaPedido.decCantidad=1;
    this.miLineaPedido.intIdArticulo=e.intId;
    this.miLineaPedido.strNombreArticulo=e.strNombre;

    this.miPedido.miListPedidoVentaLinea.push(this.miLineaPedido)

    //Compruebo si esta logueado
    if(this.storageService.isAuthenticated()){
      this.miPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
      this.strCookie=this.storageService.getCurrentSession().strCookie;
    }else{
      //IdCliente 0
      this.miPedido.intIdCliente=0;
    }

    this.compruebaPedido(this.strCookie,this.miPedido);
    this.miPedido=new PedidoAgregar();
  }

  compruebaPedido(cookie,miPedido){
    //Compruebo si existe un pedido en sesion
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.booGuardaPedidoSesion=false;
      miPedido.intId=this.miStoragePedidoService.getCurrentSessionP().intId;
      // console.log(this.storageService.getCurrentSessionP())
    }else{
      //Añado el pedido a sesion y lo inserto
      this.booGuardaPedidoSesion=true;
      miPedido.intId=0;
    } 

    // console.log(this.booGuardaPedidoSesion)
    this.enviaPedido(cookie,miPedido)
    
  }

  //FALTA COMPROBRAR SI EXISTE LA LINEA

  enviaPedido(cookie,pedido){
    this.miServicio.postPedido(cookie,pedido,true).subscribe(datos=>{
      // console.log(datos)

      if(datos.miRespuesta.booOk){
        if(this.booGuardaPedidoSesion){
          this.miPedidoSesion=datos.miListPedidoVenta[0];
          this.miPedidoSesion.miListPedidoVentaLinea=datos.miListPedidoVenta[0].miListPedidoVentaLinea;
          this.guardarSesionPedidoOnline(cookie,datos.miListPedidoVenta[0])
        }else{
          // console.log(this.storageService.getCurrentSessionP())
          this.recuperarPedido(cookie,this.miStoragePedidoService.getCurrentSessionP().intId)
        }
      }
    })
  }

  guardarSesionPedidoOnline(cookie,sesion){
    // console.log(sesion)
    this.showCarrito=true;
    this.miStoragePedidoService.setSessionPagos(sesion);

       setTimeout(()=>{
        this.recuperarPedido(cookie,this.miStoragePedidoService.getCurrentSessionP().intId)
        // if(sesion.intTipoPedido===environment.pedidoMixto){
        //   //Redirijo al resumen
        //   this.verResumen();
        // }
      }, 500)
  }

  recuperarPedido(cookie,intId){
    this.miServicio.getPedido(cookie,intId).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){
        this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length;
      }
    })
  }

  buscar(event: any){
    // console.log(event)
    if(this.strFiltro===''){
      this.getArticulos(this.strCookie)
    }

    if(event.keyCode===13){
      this.BtnBuscar();
    }
  }
  

 BtnBuscar(){
    if(this.strFiltro!=undefined){
      if(this.strFiltro.length>0){    
        this.showSpinner=true;
        this.miServicio.getArticulosBusqueda(this.strCookie,this.strFiltro).subscribe(datosBusqueda=>{
          
          if(datosBusqueda.miRespuesta.booOk){
            this.showSpinner=false;
            if(datosBusqueda.miListArticulo.length>0){
              this.titulo=datosBusqueda.miListArticulo.length + " articulos encontrados";
              this.miListArticulo=datosBusqueda.miListArticulo;
            }
            else{
              this.titulo=datosBusqueda.miListArticulo.length + " articulos encontrados";
              this.miListArticulo.splice(0,this.miListArticulo.length)
            }
          }
          else{
            this.showSpinner=false;
            this.titulo=datosBusqueda.miListArticulo.length + " articulos encontrados";
            this.miListArticulo.splice(0,this.miListArticulo.length)
          }
  
        })    
      }else{
        this.getArticulos(this.strCookie)
      }
    }
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-cliente',
  templateUrl: './layout-cliente.component.html',
  styleUrls: ['./layout-cliente.component.scss']
})
export class LayoutClienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export class FinalizaPedido {

    public intId?: number;
	public strObservaciones?:string;
	public intIdFormaPago?:number;
	public strFormaEntrega?: string;
	public strHoraEntrega?: string;
	public strSubTipo?: string;
	public intIdCliente?:number;


	// public int intId { get; set; }
	// public DateTime datFechaRegistro { get; set; }
	// public DateTime datFechaModificacion { get; set; }
	// public string strSerie { get; set; }
	// public int intEjercicio { get; set; }
	// public int intNPedido { get; set; }
	// public DateTime datFecha { get; set; }
	// public int intIdCliente { get; set; }
	// public string strNombreCliente { get; set; }
	// public string strApellidosCliente { get; set; }
	// public string strObservaciones { get; set; }
	// public string strEstado { get; set; }
	// public string strTipo { get; set; }
	// public int intIdFormaPago { get; set; }
	// public string strFormaEntrega { get; set; }
	// public string strHoraEntrega { get; set; }
	// public string strDescargado { get; set; }
	// public DateTime datFechaDescargado { get; set; }
	// public string strNotasDescargado { get; set; }
	// public decimal decBase { get; set; }
	// public decimal decCuotaIva { get; set; }
	// public decimal decCuotaReq { get; set; }
	// public decimal decCuotaIrpf { get; set; }
	// public decimal decImporte { get; set; }
	// public int intIdExterno { get; set; }
	// public string strSubTipo { get; set; }
	// public string strFinalizado { get; set; }

}

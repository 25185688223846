import { Injectable } from '@angular/core';
import { Sesion } from '../models/Sesion';
import { PedidoVenta } from '../models/pedido-venta';
import { Router } from '@angular/router';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class StoragePedidoService {
  private localStorageService;
  // private currentSession : Sesion = null;
  private currentSessionP : PedidoVenta = null;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    // this.currentSession = this.loadSessionData();
    this.currentSessionP = this.loadSessionDataP();
  }

  // setCurrentSession(session: Sesion): void {
  //   this.currentSession = session;
  //   this.localStorageService.setItem('cookieGestionWeb', JSON.stringify(session));
  //   //this.localStorageService.setItem('cookieUser', JSON.stringify(session.strCookie));
  // }

  setSessionPagos(sessionP: PedidoVenta): void {
    this.currentSessionP = sessionP;
    this.localStorageService.setItem('cookiePedidoOnline', JSON.stringify(sessionP));
  }

  // loadSessionData(): Sesion{
  //   var sessionStr = this.localStorageService.getItem('cookieGestionWeb');
  //   //var sessionStr2 = this.localStorageService.getItem('cookieUser');
  //   return (sessionStr) ? <Sesion> JSON.parse(sessionStr) : null;
  //   //(sessionStr2) ? <any> JSON.parse(sessionStr2) : null;
  // }

  loadSessionDataP(): PedidoVenta{
    var sessionStr = this.localStorageService.getItem('cookiePedidoOnline');
    return (sessionStr) ? <PedidoVenta> JSON.parse(sessionStr) : null;
  }

  // getCurrentSession(): Sesion {
  //   return this.currentSession;
  // }

  getCurrentSessionP(): PedidoVenta {
    return this.currentSessionP;
  }

  // removeCurrentSession(): void {
  //   this.localStorageService.removeItem('cookieGestionWeb');
  //   //this.localStorageService.removeItem('cookieUser');
  //   this.currentSession = null;
  // }

  removeCurrentSessionP(): void {
    this.localStorageService.removeItem('cookiePedidoOnline');
    //this.localStorageService.removeItem('cookieUser');
    this.currentSessionP = null;
  }

  // getCurrentUser(): User {
  //   var session: Sesion = this.getCurrentSession();
  //   return (session && session.user) ? session.user : null;
  // };

  // isAuthenticated(): boolean {
  //   return (this.getCurrentToken() != null) ? true : false;
  // };

  // getCurrentToken(): string {
  //   var session = this.getCurrentSession();
  //   return (session && session.miToken.access_token) ? session.miToken.access_token : null;
  // };

  isAuthenticatedP(): boolean {
    return (this.getCurrentSessionP() != null) ? true : false;
  };

  // getCurrentTokenOra(): string {
  //   var session = this.getCurrentSession();
  //   return (session && session.strTokenOra) ? session.strTokenOra : null;
  // };

  // logout(): void{
    
  //   //this.router.navigate(['/login']);
  //   //console.log(this.getCurrentSession().strCookie);

  //   // let extra: NavigationExtras = {
  //   //   queryParams: {
  //   //     'coo':this.getCurrentSession().strCookie,

  //   //   }
  //   // }
  //   // this.router.navigate(["/login"]);    

  //   window.location.href='http://infiteccontrol.infitec.es/servicios/solicita?est='+ this.getCurrentSession().strEstablecimientoId +'&pro='+  this.getCurrentSession().strProductoId+'';

  //   this.removeCurrentSession();
  // }

}

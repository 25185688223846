import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';
import { Articulo } from 'src/app/models/articulo';
import { PedidoOnlineService } from 'src/app/services/pedido-online.service';
import { StorageService } from 'src/app/services/storage.service';
import { PedidoVenta } from 'src/app/models/pedido-venta';
import { PedidoAgregarLinea, PedidoAgregar } from 'src/app/models/pedido-agregar';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';
import { VentanaEmergenteTipoPedidoComponent } from '../ventana-emergente-tipo-pedido/ventana-emergente-tipo-pedido.component';
import { environment } from 'src/environments/environment';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { VentanaEmergentePedidoNotasComponent } from '../ventana-emergente-pedido-notas/ventana-emergente-pedido-notas.component';

@Component({
  selector: 'app-detalle-articulo',
  templateUrl: './detalle-articulo.component.html',
  styleUrls: ['./detalle-articulo.component.scss']
})
export class DetalleArticuloComponent implements OnInit {
  
  // public myListImg:ListImg[] = [
  //   {id: "1", src: 'https://res.cloudinary.com/john-mantas/image/upload/v1537302064/codepen/delicious-apples/green-apple2.png'},
  //   {id: "2", src: 'https://res.cloudinary.com/john-mantas/image/upload/v1537303532/codepen/delicious-apples/half-apple.png'},
  //   {id: "3", src: 'https://res.cloudinary.com/john-mantas/image/upload/v1537303160/codepen/delicious-apples/green-apple-flipped.png'},
  //   {id: "4", src: 'https://res.cloudinary.com/john-mantas/image/upload/v1537303708/codepen/delicious-apples/apple-top.png'},
  // ];

  public myListImg:ListImg[] = [];

  strCantidad=1;

  miArticulo:Articulo=new Articulo();

  @ViewChild("imgMuestra", {static: false}) imgField:ElementRef;

  strCookie:string;

  miPedido:PedidoAgregar=new PedidoAgregar();
  miLineaPedido:PedidoAgregarLinea=new PedidoAgregarLinea();
  booGuardaPedidoSesion=false;
  miPedidoSesion:PedidoVenta=new PedidoVenta();
  showCarrito=false;
  totalArticulos:number=0;
  showSpinner=false;
  booNavigate=false;

  constructor(private router:Router,private route: ActivatedRoute, private miServicio:PedidoOnlineService, private dialog: MatDialog,private storageService: StorageService,
    private miStoragePedidoService:StoragePedidoService) {
    this.route.queryParams.subscribe(params => {
      // console.log(params["coo"])
      this.strCookie=params["coo"];
    });
   }

  ngOnInit() {
    
    setTimeout(() => {
      if(this.storageService.isAuthenticated()){
        this.strCookie=this.storageService.getCurrentSession().strCookie
        this.getArticulos(this.storageService.getCurrentSession().strCookie);
      }else{
        this.getArticulos(this.strCookie);
      }

      if(this.miStoragePedidoService.isAuthenticatedP()){
        this.showCarrito=true;
        this.recuperarPedido(this.strCookie,this.miStoragePedidoService.getCurrentSessionP().intId);
        // this.totalArticulos=this.storageService.getCurrentSessionP().miListPedidoVentaLinea.length;
      }else{
        this.showCarrito=false;
      }

      // this.getArticulos();
    }, 10); 


    
    
  }

  changeImg(e){
    for (var i = 0; i < this.myListImg.length; i++) {
      if (this.myListImg[i].id === e.target.id) {
          this.imgField.nativeElement.src=this.myListImg[i].src;
      }
    }
  }

  getArticulos(cookie){
    this.showSpinner=true;
    this.route.params.subscribe(routeParams => {
      this.listarArticulos(cookie,routeParams.intArtId);
      // console.log(routeParams)
    });
  }

  listarArticulos(cookie,intId){
    this.miServicio.getArticuloDetalle(cookie,intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.miArticulo=datos.miListArticulo[0]
        // console.log(datos.miListArticulo[0].strImagen1Base64)
        this.imgField.nativeElement.src="data:image/gif;base64," + datos.miListArticulo[0].strImagen1Base64;
        this.myListImg.push(
          {id: "1", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen1Base64},
          {id: "2", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen2Base64},
          {id: "3", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen3Base64},
          {id: "4", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen4Base64},
          );
      }else{
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde.",
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  add(){
    this.strCantidad+=1;
  }

  remove(){
    if(this.strCantidad>1){
      this.strCantidad-=1;
    }
  }

  addCarrito(){
    //CAROL 27/08/21
    //Cambio para Lomillos
    //Verifico si existe pedido, si no existe pregunto tipo de pedido

    if(!this.miStoragePedidoService.isAuthenticatedP()){
      let dialogRef = this.dialog.open(VentanaEmergenteTipoPedidoComponent, {
        width: '600px'
      });

      dialogRef.afterClosed().subscribe(data=>{
        // console.log(data)
        if(data!=undefined){
          this.showSpinner=true;
          //Compruebo si esta logueado
          if(this.storageService.isAuthenticated()){
            this.miPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
            this.strCookie=this.storageService.getCurrentSession().strCookie;
          }else{
            //IdCliente 0
            this.miPedido.intIdCliente=0;
          }

          this.miLineaPedido.intId=0;
          this.miLineaPedido.decCantidad=this.strCantidad;
          this.miLineaPedido.intIdArticulo=this.miArticulo.intId;
          this.miLineaPedido.strNombreArticulo=this.miArticulo.strNombre;

          this.miPedido.miListPedidoVentaLinea.push(this.miLineaPedido);

          switch(data.intTipo){
            case 1:
              //standard
              this.miPedido.intTipoPedido=environment.pedidoStandard;
              this.compruebaPedido(this.strCookie,this.miPedido);
              break;

            case 2:
              //pedido por nota
              // this.miPedido.intTipoPedido=environment.pedidoMixto;
              // this.compruebaPedido(this.strCookie,this.miPedido);

              //Verifico si esta logueado
              if(this.storageService.isAuthenticated()){
                //Continua con el pedido
                let dialogRef = this.dialog.open(VentanaEmergentePedidoNotasComponent, {
                  width: '600px'
                });
            
                dialogRef.afterClosed().subscribe(data=>{
                  console.log(data)
                  if(data!=undefined){
                    this.showSpinner=true;
                    //Asigno valores
                    this.miPedido.intTipoPedido=environment.pedidoEscrito;
                    this.miPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
                    this.miPedido.strObservaciones=data.strNotas;
                    this.miPedido.intId=0;
                    //Inserta pedido por notas
                    this.enviaPedidoNotas(this.strCookie,this.miPedido)
                  }
                })
              }else{
                //Redirijo al login
                let extra: NavigationExtras = {
                  queryParams: {
                    'coo':this.strCookie
                  }
                }
          
                this.router.navigate(["/inicio/login"], extra);
              }
              break;
          }
        }
      })
    }
    else{
      //Compruebo si esta logueado
      if(this.storageService.isAuthenticated()){
        // this.miPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
        this.strCookie=this.storageService.getCurrentSession().strCookie;
      }
      // else{
      //   //IdCliente 0
      //   // this.miPedido.intIdCliente=0;
      // }

      this.miLineaPedido.intId=0;
      this.miLineaPedido.decCantidad=this.strCantidad;
      this.miLineaPedido.intIdArticulo=this.miArticulo.intId;
      this.miLineaPedido.strNombreArticulo=this.miArticulo.strNombre;

      this.miPedido.miListPedidoVentaLinea.push(this.miLineaPedido);
      this.compruebaPedido(this.strCookie,this.miPedido);
    }
  }

  compruebaPedido(cookie,miPedido){
    //Compruebo si existe un pedido en sesion
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.booGuardaPedidoSesion=false;
      miPedido.intId=this.miStoragePedidoService.getCurrentSessionP().intId;
    }else{
      //Añado el pedido a sesion y lo inserto
      this.booGuardaPedidoSesion=true;
      miPedido.intId=0;
      
    } 
    this.enviaPedido(cookie,miPedido)
  }

  enviaPedidoNotas(cookie,pedido){
    this.miServicio.postPedidoNotas(cookie,pedido,true).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;

      if(datos.miRespuesta.booOk){
        //OK
        this.miPedido=new PedidoAgregar();
        this.ventanaemergente("Su pedido se ha realizado correctamente. En breves recibirá un email de confirmación.",false,true);
      }
    })
  }

  ventanaemergente(strTexto,showError,showOk){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:strTexto,
        boton:false,
        showError:showError,
        showOk:showOk
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //FALTA COMPROBRAR SI EXISTE LA LINEA

  enviaPedido(cookie,pedido){
    this.miServicio.postPedido(cookie,pedido,true).subscribe(datos=>{

      if(datos.miRespuesta.booOk){
        if(this.booGuardaPedidoSesion){
          this.miPedidoSesion=datos.miListPedidoVenta[0];
          this.miPedidoSesion.miListPedidoVentaLinea=datos.miListPedidoVenta[0].miListPedidoVentaLinea;
          this.guardarSesionPedidoOnline(cookie,datos.miListPedidoVenta[0])
        }else{
          this.recuperarPedido(cookie,this.miStoragePedidoService.getCurrentSessionP().intId)
        }
      }
    })
  }

  guardarSesionPedidoOnline(cookie,sesion){
    // console.log(sesion)
    this.showCarrito=true;
    this.miStoragePedidoService.setSessionPagos(sesion);

       setTimeout(()=>{
        this.recuperarPedido(cookie,this.miStoragePedidoService.getCurrentSessionP().intId);
        // if(sesion.intTipoPedido===environment.pedidoMixto){
        //   //Redirijo al resumen
        //   this.verResumen();
        // }
      }, 500)
  }

  recuperarPedido(cookie,intId){
    this.miServicio.getPedido(cookie,intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length;

        if(this.booNavigate){
          this.navegar()
        }
      }
      
    })
  }

  verResumen(){
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/resumen'])
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      this.router.navigate(['./menu/resumen'], extra)
    }
  }


  comprar(){
    this.addCarrito();
    this.booNavigate=true;
  }

  navegar(){
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/resumen'])
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      this.router.navigate(['./menu/resumen'], extra)
    }
  }

}

export interface ListImg{
  id:any;
  src:string;
}

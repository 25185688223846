import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Cliente } from 'src/app/models/cliente';
import { PedidoVentaResult } from 'src/app/models/pedido-venta-result';
import { PedidoVenta } from 'src/app/models/pedido-venta';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';

@Component({
  selector: 'app-pedidos-cliente',
  templateUrl: './pedidos-cliente.component.html',
  styleUrls: ['./pedidos-cliente.component.scss']
})
export class PedidosClienteComponent implements OnInit {

  showCarrito=false;
  totalArticulos=0;
  strCookie:string;
  
  showSpinner=false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  data:PedidoVenta[]=[];
  displayedColumns = ['intEjercicio','strSerie','intNPedido', 'datFecha', 'decImporte', 'intId'];
  dataSource;

  booSinDatos=false;
  booMuestraPedidos=false;

  constructor(private miservicio:RestService, private dialog: MatDialog, private storagaService:StorageService, private router:Router, private route: ActivatedRoute,
    private miStoragePedidoService:StoragePedidoService) {
    
   }

  ngOnInit() {
    // if(this.storagaService.isAuthenticated()){
      this.strCookie=this.storagaService.getCurrentSession().strCookie;
      this.verPedidosCliente();
      this.verPedido();
      // console.log(this.storagaService.getCurrentUser().strEntidadId)
    // }
  }

  verPedidosCliente(){
    this.showSpinner=true;
    // if(this.storagaService.isAuthenticatedP()){
      this.miservicio.getPedidoCliente(this.strCookie, this.storagaService.getCurrentUser().strEntidadId,false).subscribe(datos=>{
        // console.log(datos)
        // if(datos.booOk){

        // }
        if(datos.miRespuesta.booOk){
          this.showSpinner=false;
          this.booMuestraPedidos=true;
          this.booSinDatos=false;
          this.data=datos.miListPedidoVenta;
          // this.listFiltrada=datos.miListCliente; 
          this.dataSource=new MatTableDataSource(this.data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator=this.paginator;
          // this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length
        }else{
          this.booMuestraPedidos=false;
          this.booSinDatos=true;
          this.showSpinner=false;
        }
      })
    // }
  }

  verPedido(){
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.miservicio.getPedido(this.strCookie, this.miStoragePedidoService.getCurrentSessionP().intId).subscribe(datos=>{
        // console.log(datos)
        if(datos.miRespuesta.booOk){
          this.showCarrito=true;
          this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length
        }
      })
    }
  }

  verDetalle(e){
    // console.log(e)

    let extra: NavigationExtras = {
      queryParams: {
        'id':e.intId
      }
    }

    this.router.navigate(['./cliente/detalle-pedido'], extra)
  }

  verResumen(){
    this.router.navigate(['/menu/resumen'])
  }

}

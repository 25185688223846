import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Pasarela } from 'src/app/models/pasarela';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { OrdenCobro } from 'src/app/models/OrdenCobro';
import { Establecimiento } from 'src/app/models/establecimiento';
import { Cliente } from 'src/app/models/cliente';
import { StorageService } from 'src/app/services/storage.service';
import { Sesion } from 'src/app/models/Sesion';
import { SesionPagos } from 'src/app/models/sesion-pagos';
import { LoginService } from 'src/app/services/login.service';
// import { OrdenCobroObservacion } from 'src/app/models/ordenCobroObservacion';
import { MatDialog } from '@angular/material';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { environment } from 'src/environments/environment';
import { OrdenCobroResult } from 'src/app/models/OrdenCobroResult';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnInit {

  miPasarela:Pasarela=new Pasarela();
  @ViewChild('submit', {static: false}) elSubmit : ElementRef;
  private cookieInfi:string;
  private cookieTpv:string;
  showInfi=true;
  muestraResumen=false;
  miOrden:OrdenCobro=new OrdenCobro();
  miEstablecimiento:Establecimiento=new Establecimiento();
  miCliente:Cliente=new Cliente();

  miSesionP:SesionPagos=new SesionPagos();

  //LOGO
  textoApp:string;
  imgLogo:string;

  // miListOrdenObservaciones: OrdenCobroObservacion[] = [];

  //opciones de pago
  correo:any;
  cuenta:any;
  cajaCorreo=false;
  cajaCuenta=false;
  procesoFinalizacionPago=false;

  opcionPago:any;

  btndisabled=true;
  muestraBtnFinal=false;

  constructor(private route: ActivatedRoute, private miservicio:RestService, private storageService: StorageService, private miRestService: LoginService,
              private router:Router, private dialog: MatDialog) {
    this.route.queryParams.subscribe(params => {
      this.cookieInfi=params["coo"];
      this.cookieTpv=params["id"];
    });
   }

  ngOnInit() {     
    this.send();
    // this.showInfi=true;
    // sessionStorage.setItem('strCookieI', this.cookieInfi);
    // sessionStorage.setItem('strCookieT', this.cookieTpv);
    this.otrosDatosEstablecimiento();
    
    //Si selecciona pasarela de pago. respuesta pasarela
    // this.recuperoPago();
  }

  otrosDatosEstablecimiento(){
    // this.miRestService.getLogo().subscribe(datos=>{
    //   // console.log(datos)
    //   this.textoApp=datos.strMensaje;
    //   this.imgLogo=datos.strValor;
    // })
  }

  guardaDatosSesion(){
    //Elimino los datos se sesion
    // this.storageService.removeCurrentSessionP();
    this.miSesionP.strCookieI=this.cookieInfi;
    this.miSesionP.strCookieT=this.cookieTpv;
    // this.storageService.setSessionPagos(this.miSesionP);
  }

  pago(){
    this.elSubmit.nativeElement.click();
  }

  send(){
    this.miservicio.postPasarela(this.cookieInfi, this.cookieTpv).subscribe((datos:any)=>{
      // console.log(datos)
      this.miPasarela=datos as Pasarela;
      if(datos.booOk){
        // this.detalles();
        this.datosEstablecimiento();
        this.guardaDatosSesion();
        setTimeout(() => {
          this.showInfi=false;
          this.muestraResumen=true;
        }, 2000);
      }
      // console.log(this.miPasarela)      
    })
  }

  /*
  detalles(){
    this.miservicio.getDetalle(this.cookieInfi, this.cookieTpv).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miOrden=datos.miListOrdenCobro[0];
        // console.log(this.miOrden)

        this.miservicio.getClientePago(this.cookieInfi,this.miOrden.intIdCliente,'','').subscribe(data=>{
          // console.log(data);
          this.miCliente=data.miListCliente[0];
          this.listarObservaciones();
        })
      }
    })
  }
  */

  datosEstablecimiento(){
    this.miservicio.getEst(this.cookieInfi).subscribe(datos=>{
      // console.log(datos)
      this.miEstablecimiento=datos[0];
      // console.log(this.miEstablecimiento)
    })
  }

  login(){
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.cookieInfi
      }
    }
    this.router.navigate(["/inicio/login"], extra);    
  }

  descargarPDF(){
    const linkSource = 'data:application/pdf;base64,' + this.miOrden.strArchivoTransaccion;
    const downloadLink = document.createElement("a");
    const fileName = "Factura_"+ this.miOrden.strNumeroFacturaExterno +".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();        
  }

  /*
  listarObservaciones(){
    this.miservicio.getOrdenCobroObservaciones(this.cookieInfi,this.miOrden.intId).subscribe(datos=>{
      // console.logs(datos)
      if(datos.miRespuesta.booOk){
        this.miListOrdenObservaciones=datos.miListOrdenCobroObservacion;        
      }
    })
  }
  */

  pasarelaPago(){
    this.opcionPago="P";
    this.btndisabled=false;
    this.cajaCorreo=false;
    this.cajaCuenta=false;
    this.muestraBtnFinal=false;
    this.showInfi=true;
    this.muestraResumen=false;
    // this.procesoFinalizacionPago=false;
    setTimeout(() => {      
      this.showInfi=false;
      this.muestraResumen=false;
      this.pago();
    }, 2000);
  }

  transferenciaPago(){
    this.opcionPago="T";
    this.cajaCorreo=true;
    this.cajaCuenta=false;
    this.btndisabled=true;
    this.muestraBtnFinal=true;
    this.procesoFinalizacionPago=true;
    setTimeout(() => { 
      document.getElementById('correo').focus();     
    }, 500);    
  }

  cagoCuentaPago(){
    this.opcionPago="C";
    this.cajaCorreo=true;
    this.cajaCuenta=true;
    this.btndisabled=true;
    this.muestraBtnFinal=true;
    this.procesoFinalizacionPago=true;
    setTimeout(() => { 
      document.getElementById('correo').focus();
    }, 500); 
  }

  finalizarPago(){
    // console.log(this.opcionPago)
    if(this.opcionPago!=undefined){
      switch(this.opcionPago){
        // case "P":          
        //   this.pago();
        //   break;
  
        case "T":
          // console.log(this.correo)
          if(this.correo!=undefined && this.correo!=''){
            //CONTINUAR PAGO
            this.aceptarPago(this.correo,"T");
          }else{
            let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
              width: '600px',
              data:{
                texto:"Indique la dirección de correo electrónico en la que desea recibir el número de cuenta para realizar el pago.",
                boton:false
              }
            }); 
          }          
          break;
        
        case "C":
          if(this.cuenta!=undefined && this.cuenta!='' && this.correo!=undefined && this.correo!=''){
            //CONTINUAR PAGO
            this.miOrden.strNumCuentaCliente=this.cuenta;
            this.añadirNumCuentaCliente();
          }else{
            let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
              width: '600px',
              data:{
                texto:"Asegurese de rellenar todos los campos.",
                boton:false
              }
            }); 
          }
          break;
      }
    }else{
      let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
        width: '600px',
        data:{
          texto:"Seleccione uno de los métodos de pago.",
          boton:false
        }
      });      
    }    
  }

  aceptarPago(strEmail,strFormaPago){
    this.miservicio.pagoTransf(this.cookieInfi,this.miOrden.intIdCliente,this.miOrden.intId,strEmail,strFormaPago).subscribe(datos=>{
      // console.log(datos)
      if(datos.booOk){
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha realizado correctamente la operacion, en breves recibirá un correo electrónico de confirmación.",
            boton:false,
            btnError:false
          }
        });
        // setTimeout(() => { 
        //   this.redirigirPagoFinalizado(strFormaPago)
        // }, 1000); 
        dialogRef.afterClosed().subscribe(()=>{
          this.redirigirPagoFinalizado(strFormaPago)
        })

      }else{
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde. Si el error persiste por favor llame al " + this.miEstablecimiento.strTelefono1,
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  añadirNumCuentaCliente(){
    this.miservicio.addCC(this.cookieInfi,this.miOrden).subscribe(datos=>{
      // console.log(datos)
      if(datos.booOk){
        this.aceptarPago(this.correo,"C");
      }             
    })
  }

  redirigirPagoFinalizado(strParam){
    let extra: NavigationExtras = {
      queryParams: {
        'param':strParam
      }
    }
    this.router.navigate(["/menu/PagoFinalizado"]);    
  }



  // //Recupero los datos del pago
  // recuperoPago(){
  //   this.miservicio.recuperaDato(this.storageService.getCurrentSessionP().strCookieI,this.storageService.getCurrentSessionP().strCookieT).subscribe((datos:OrdenCobroResult)=>{
  //     console.log(datos)
  //     if(datos.miRespuesta.booOk){
  //       // if(datos.miListOrdenCobro[0].strEstadoTransaccion=environment.estadoPasarelaOk)
  //       switch(datos.miListOrdenCobro[0].strEstadoTransaccion){
  //         case environment.estadoPasarelaPendiente:
  //           document.getElementById("success-box").style.display="none";
  //           document.getElementById("error-box").style.display="block";
  //           break;
          
  //         case environment.estadoPasarelaError:
  //           document.getElementById("success-box").style.display="none";
  //           document.getElementById("error-box").style.display="block";
  //           break;

  //         case environment.estadoPasarelaOk:
  //           document.getElementById("success-box").style.display="block";
  //           document.getElementById("error-box").style.display="none";
  //           break;
  //       }
  //     }
  //   })
  // }

}

import { User } from './User';
import { Token } from './Token';

export class Sesion  {
    // public strToken?: string; 
    public strCookie?: string; 
    public strMensaje?: string; 
    public strHostSignalR?: string; 
    public strEstablecimientoId?: string;
    public strProductoId?: string;
    public strEstablecimientoNombre?: string;
    public user: User = new User();

    // Adiconales
    public strNombre?: string;
    public strNombreTitulo?: string;
    public miToken: Token = new Token();
    public strLogo?:any;
    public strNombreApp?:any;
    

    // public strToken: string;
    // public strMensaje:string;
    // public expires:number;
    // public token:Token;
    // public user: User;
    // public strCookie?:string;

    //Datos generales de la aplicacion

    // public strNombre?:string;
    // public strNombreTitulo?:string;
    // public strHostSignalR?:string;
    // public strEstablecimientoId?:string;
    // public strProductoId?:string;

}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestService } from './services/rest.service';
import { AppMaterialModule } from './app-material.module';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { PagosComponent } from './components/pagos/pagos.component';
import { LoginService } from './services/login.service';
import { PasarelaComponent } from './components/pasarela/pasarela.component';
import { DatePipe } from '@angular/common';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { PagoFinalizadoComponent } from './components/pago-finalizado/pago-finalizado.component';
import { VentanaEmergentePagosComponent } from './components/ventana-emergente-pagos/ventana-emergente-pagos.component';
import { CartaComponent } from './carta/carta.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { DetalleArticuloComponent } from './components/detalle-articulo/detalle-articulo.component';
import { ResumenCompraComponent } from './components/resumen-compra/resumen-compra.component';
import { FinalizarCompraComponent } from './components/finalizar-compra/finalizar-compra.component';
import { PoliticaCondicionesEnvioFacturacionComponent } from './components/politica-condiciones-envio-facturacion/politica-condiciones-envio-facturacion.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { APIResolver } from './services/resolve';
import { PedidoOnlineService } from './services/pedido-online.service';
import { ClienteComponent } from './components/cliente/cliente.component';
import { DetalleClienteComponent } from './components/detalle-cliente/detalle-cliente.component';
import { LayoutClienteComponent } from './components/layout-cliente/layout-cliente.component';
import { SidenavClienteComponent } from './components/sidenav-cliente/sidenav-cliente.component';
import { PedidoFinalizadoComponent } from './components/pedido-finalizado/pedido-finalizado.component';
import { PedidosClienteComponent } from './components/pedidos-cliente/pedidos-cliente.component';
import { StoragePedidoService } from './services/storage-pedido.service';
import { DetallePedidoComponent } from './components/detalle-pedido/detalle-pedido.component';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { FooterComponent } from './components/footer/footer.component';
import { VentanaEmergentePagoFinalizadoComponent } from './components/ventana-emergente-pago-finalizado/ventana-emergente-pago-finalizado.component';
import { ConfirmacionRegistroComponent } from './components/confirmacion-registro/confirmacion-registro.component';
import { VentanaEmergenteTipoPedidoComponent } from './components/ventana-emergente-tipo-pedido/ventana-emergente-tipo-pedido.component';
import { VentanaEmergentePedidoNotasComponent } from './components/ventana-emergente-pedido-notas/ventana-emergente-pedido-notas.component';
import { InicioComponent } from './components/inicio/inicio.component';
//import { EventEmiterService } from './services/nav.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    PagosComponent,
    PasarelaComponent,
    VentanaEmergenteComponent,
    ConfiguracionComponent,
    PagoFinalizadoComponent,
    VentanaEmergentePagosComponent,
    CartaComponent,
    SidenavComponent,
    LayoutComponent,
    LoginLayoutComponent,
    DetalleArticuloComponent,
    ResumenCompraComponent,
    FinalizarCompraComponent,
    PoliticaCondicionesEnvioFacturacionComponent,
    MenuListItemComponent,
    ClienteComponent,
    DetalleClienteComponent,
    LayoutClienteComponent,
    SidenavClienteComponent,
    PedidoFinalizadoComponent,
    PedidosClienteComponent,
    DetallePedidoComponent,
    PoliticaCookiesComponent,
    FooterComponent,
    VentanaEmergentePagoFinalizadoComponent,
    ConfirmacionRegistroComponent,
    VentanaEmergenteTipoPedidoComponent,
    VentanaEmergentePedidoNotasComponent,
    InicioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,


    MatNativeDateModule
  ],
  providers: [RestService, APIResolver, StorageService, StoragePedidoService, PedidoOnlineService, LoginService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe
              
             ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }

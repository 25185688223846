import { Component, OnInit, Inject } from '@angular/core';
import { PedidoOnlineService } from 'src/app/services/pedido-online.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.scss']
})
export class PoliticaCookiesComponent implements OnInit {

  strPoliticaCookies:string;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: any,
              private miServicio:PedidoOnlineService) { }

  ngOnInit() {
    this.verPoliticaCookies();
  }

  verPoliticaCookies(){
    this.miServicio.getPoliticaCookies(this.data).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.strPoliticaCookies=datos.miListEstablecimiento[0].strPoliticaCookies;
      }else{
        this.strPoliticaCookies=datos.miRespuesta.strMensaje;
      }
    })
  }

  aceptar(){
    this.thisDialogRef.close(true);
  }

  cancelar(){
    this.thisDialogRef.close(false);
  }

}
